// @flow

import React, { Component, type Node } from 'react'
import Helmet from 'react-helmet'
import { Link } from 'gatsby'
import classnames from 'classnames'
import { Link as ScrollLink, Element } from 'react-scroll'

import { Page } from 'shared-components'

import getScrollPosition from '../utils/getScrollPosition'

import './branding.scss'

const GH_URL = 'https://github.com/MainframeHQ/branding/raw/master'

const LOGOS = [
  {
    name: 'light',
    featured: true,
    folder: 'Light',
  },
  {
    name: 'dark',
    featured: true,
    folder: 'Dark',
  },
  {
    name: 'red-rose',
    featured: false,
    folder: 'Red-Rose',
  },
  {
    name: 'light blue',
    featured: false,
    folder: 'Blue',
  },
]

const ILLUSTRATIONS = [
  {
    name: 'Mainframe Layer',
    file: 'Layer',
  },
  {
    name: 'Encryption',
    file: 'Encryption',
  },
  {
    name: 'Dark Routing',
    file: 'DarkRouting',
  },
  {
    name: 'MFT',
    file: 'MFT',
  },
  {
    name: 'Peer to Peer Network',
    file: 'PPNetwork',
  },
  {
    name: 'Interoperability',
    file: 'Interoperability',
  },
  {
    name: 'Freedom',
    file: 'Freedom',
  },
  {
    name: 'Censorship Resistant',
    file: 'CensorshipResistant',
  },
  {
    name: 'Airdrop',
    file: 'Airdrop',
  },
  {
    name: 'Mainframe Flag',
    file: 'Flag',
  },
]

const FONTS = [
  {
    class: 'galano',
    name: 'Galano',
    line1: 'Titles',
    line2: 'Sub-titles',
    link: 'https://www.fontfabric.com/galano-grotesque/',
  },
  {
    class: 'pop-bold',
    name: 'Poppins Bold',
    line1: 'Titles / Sub-titles',
    line2: '(when Galano is unavailable)',
    link: 'https://fonts.google.com/specimen/Poppins',
  },
  {
    class: 'pop-medium',
    name: 'Poppins Medium',
    line1: 'Sub-titles',
    line2: 'Very short paragraphs',
    link: 'https://fonts.google.com/specimen/Poppins',
  },
  {
    class: 'pop-reg',
    name: 'Poppins Regular',
    line1: 'Long paragraphs',
    line2: '',
    link: 'https://fonts.google.com/specimen/Poppins',
  },
  {
    class: 'muli',
    name: 'Muli Regular',
    line1: 'Text heavy document',
    line2: 'Very long paragraphs',
    link: 'https://fonts.google.com/specimen/Muli',
  },
]

type State = {
  fixed: boolean,
}

export default class BrandingPage extends Component<{}, State> {
  state = {
    fixed: false,
  }

  componentDidMount() {
    window.addEventListener('scroll', this.positionMenu)
  }

  positionMenu = () => {
    const scrollPos = getScrollPosition()

    if (!this.state.fixed && scrollPos > 185) {
      this.setState({ fixed: true })
    } else if (this.state.fixed && scrollPos <= 185) {
      this.setState({ fixed: false })
    }
  }

  renderLogos = (): Array<Node> => {
    return LOGOS.map(item => {
      return (
        <div
          key={classnames('row-items', item.name)}
          className={classnames('row-items', item.name)}>
          <div className="item">
            <div
              className={classnames('image symbol', {
                featured: item.featured,
              })}>
              <div className="download">
                <div className="subcontent">
                  <div className="download-title">Download</div>
                  <div className="icons">
                    <a
                      target="_new"
                      download={`MF_Symbol_${item.folder}_BG.png`}
                      href={`${GH_URL}/Logo/PNG/${item.folder}_BG/MF_Symbol_${
                        item.folder
                      }_BG.png`}
                      className="icon-png">
                      PNG
                    </a>
                    <a
                      href={`${GH_URL}/Logo/EPS/${item.folder}_BG/MF_Symbol_${
                        item.folder
                      }_BG.eps`}
                      className="icon-eps">
                      EPS
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="item-title">Mainframe Symbol</div>
            <div className="item-description">{`When the Mainframe name is not needed and used on a ${
              item.name
            } background.`}</div>
          </div>

          <div className="item">
            <div
              className={classnames('image vertical', {
                featured: item.featured,
              })}>
              <div className="download">
                <div className="subcontent">
                  <div className="download-title">Download</div>
                  <div className="icons">
                    <a
                      target="_new"
                      download={`MF_Logo_Vertical_${item.folder}_BG.png`}
                      href={`${GH_URL}/Logo/PNG/${
                        item.folder
                      }_BG/MF_Logo_Vertical_${item.folder}_BG.png`}
                      className="icon-png">
                      PNG
                    </a>
                    <a
                      href={`${GH_URL}/Logo/EPS/${
                        item.folder
                      }_BG/MF_Logo_Vertical_${item.folder}_BG.eps`}
                      className="icon-eps">
                      EPS
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="item-title">
              Mainframe Logo <span>(Vertical) </span>
            </div>
            <div className="item-description">{`When content is laid out vertically and used on a ${
              item.name
            } background.`}</div>
          </div>

          <div className="item">
            <div
              className={classnames('image horizontal', {
                featured: item.featured,
              })}>
              <div className="download">
                <div className="subcontent">
                  <div className="download-title">Download</div>
                  <div className="icons">
                    <a
                      target="_new"
                      download={`MF_Logo_Horizontal_${item.folder}_BG.png`}
                      href={`${GH_URL}/Logo/PNG/${
                        item.folder
                      }_BG/MF_Logo_Horizontal_${item.folder}_BG.png`}
                      className="icon-png">
                      PNG
                    </a>
                    <a
                      href={`${GH_URL}/Logo/EPS/${
                        item.folder
                      }_BG/MF_Logo_Horizontal_${item.folder}_BG.eps`}
                      className="icon-eps">
                      EPS
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="item-title">
              Mainframe Logo <span>(Horizontal) </span>
            </div>
            <div className="item-description">
              {`When content is laid out horizontally and used on a ${
                item.name
              } background.`}
            </div>
          </div>
        </div>
      )
    })
  }

  render() {
    return (
      <Page
        noHeader
        className={classnames('branding-page', { fixed: this.state.fixed })}>
        <Helmet title="Brand Guidelines - Mainframe - Decentralized Application Platform" />
        <div className="top">
          <Link className="logo" to="/">
            Home
          </Link>
          <p>Brand Guidelines</p>
        </div>
        <div className="menu">
          <div className="menu-items">
            <ScrollLink spy activeClass="active" to="logos" smooth>
              Logos
            </ScrollLink>
            <ScrollLink spy activeClass="active" to="colors" smooth>
              Colors
            </ScrollLink>
            <ScrollLink spy activeClass="active" to="illustrations" smooth>
              Illustrations
            </ScrollLink>
            <ScrollLink spy activeClass="active" to="fonts" smooth>
              Fonts
            </ScrollLink>
          </div>
        </div>
        <Element name="logos" className="section gray">
          <div className="container">
            <h2>Logo</h2>
            <div className="box">
              {"Don't"} combine the Mainframe name or logos, or any portion of
              any of them, with any other logo, company name, mark, or generic
              terms. Please {"don't"} edit, modify, distort, rotate, or recolor
              the logo.
            </div>

            <div className="logos">{this.renderLogos()}</div>
          </div>
        </Element>
        <Element name="colors" className="section">
          <div className="container">
            <h2>Colors</h2>
            <div className="box">
              Mainframe incorporates red-rose, light blue, dark blue and black
              colors throughout its branding. Please use the following colors
              accordingly.
            </div>

            <div className="colors">
              <div className="row-items">
                <div className="item color white">
                  <div className="item-title">White</div>
                  <div className="item-description">
                    <p>#FFFFFF</p>
                    <p>R 255 G 255 B 255</p>
                    <p>C 0 M 0 Y 0 K 0</p>
                  </div>
                </div>
                <div className="item color red-rose">
                  <div className="item-title">Red-Rose</div>
                  <div className="item-description">
                    <p>#DA1157</p>
                    <p>R 218 G 17 B 87</p>
                    <p>C 10 M 100 Y 50 K 0</p>
                    <p>Pantone Rubine Red U</p>
                  </div>
                </div>
                <div className="item color light-blue">
                  <div className="item-title">Light Blue</div>
                  <div className="item-description">
                    <p>#00A7E7 </p>
                    <p>R 0 G 167 B 231 </p>
                    <p>C 90 M 0 Y 0 K 0</p>
                    <p> Pantone 2995 U</p>
                  </div>
                </div>
                <div className="item color dark-blue">
                  <div className="item-title">Dark Blue</div>
                  <div className="item-description">
                    <p>#1F3464</p>
                    <p>R 31 G 52 B 100</p>
                    <p>C 100 M 85 Y 30 K 20</p>
                    <p>Pantone 280 U</p>
                  </div>
                </div>
                <div className="item color black">
                  <div className="item-title">Black</div>
                  <div className="item-description">
                    <p>#232323</p>
                    <p>R 35 G 35 B 35 </p>
                    <p>C 0 M 0 Y 0 K 95 </p>
                    <p>Pantone Black U</p>
                  </div>
                </div>
                <div className="item color none" />
              </div>
            </div>
          </div>
        </Element>
        <Element name="illustrations" className="section gray">
          <div className="container">
            <h2>Illustrations</h2>
            <div className="box">
              {"Mainframe's"} illustrations convey the mission and values of the
              company. Please use them accordingly.
            </div>
            <div className="illustrations">
              <div className="row-items">
                <div className={classnames('item', 'mft-icon')}>
                  <div className="image">
                    <div className="download">
                      <div className="subcontent">
                        <div className="download-title">Download</div>
                        <div className="icons">
                          <a
                            target="_new"
                            download={`Mainframe_MFT_icon.png`}
                            href={`${GH_URL}/Illustrations/Mainframe_MFT_icon.png`}
                            className="icon-png">
                            PNG
                          </a>
                          <a
                            target="_new"
                            download={`Mainframe_MFT_icon.svg`}
                            href={`${GH_URL}/Illustrations/Mainframe_MFT_icon.svg`}
                            className="icon-svg">
                            PNG
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="item-title">
                    MFT official icon (for exchange)
                  </div>
                </div>
                {ILLUSTRATIONS.map(item => {
                  return (
                    <div
                      key={item.file}
                      className={classnames('item', item.file)}>
                      <div className="image">
                        <div className="download">
                          <div className="subcontent">
                            <div className="download-title">Download</div>
                            <div className="icons">
                              <a
                                target="_new"
                                download={`MF_Illustration_${item.file}.png`}
                                href={`${GH_URL}/Illustrations/MF_Illustration_${
                                  item.file
                                }.png`}
                                className="icon-png">
                                PNG
                              </a>
                              <a
                                target="_new"
                                download={`MF_Illustration_${item.file}.svg`}
                                href={`${GH_URL}/Illustrations/MF_Illustration_${
                                  item.file
                                }.svg`}
                                className="icon-svg">
                                PNG
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="item-title">{item.name}</div>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </Element>
        <Element name="fonts" className="section">
          <div className="container">
            <h2>Fonts</h2>
            <div className="box">
              {"Mainframe's"} fonts are free of use. Font weights have specific
              purposes, please use them accordingly.
            </div>
            <div className="fonts">
              <div className="row-items">
                {FONTS.map(item => {
                  return (
                    <div
                      key={item.class}
                      className={classnames('item', item.class)}>
                      <div className="image">
                        <div className="download">
                          <div className="subcontent">
                            <div className="download-title">Download</div>
                            <div className="icons">
                              <a
                                target="_new"
                                href={item.link}
                                className="icon-download">
                                Download Font
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="item-title">{item.name}</div>
                      <div className="item-description">
                        <p>{item.line1}</p>
                        <p>{item.line2}</p>
                      </div>
                    </div>
                  )
                })}
                <div className="item" />
              </div>
            </div>
          </div>
        </Element>
        <div className="section">
          <a
            href="https://codeload.github.com/MainframeHQ/branding/zip/master"
            target="_new"
            className="mf-button">
            <span>Download all assets</span>
          </a>
        </div>
      </Page>
    )
  }
}
