export default () => {
  if (typeof window.pageYOffset !== 'undefined') {
    //most browsers except IE before #9
    return window.pageYOffset
  } else {
    const body = document.body //IE 'quirks'
    let documentElement = document.documentElement //IE with doctype
    documentElement = documentElement.clientHeight ? documentElement : body
    return documentElement.scrollTop
  }
}
